import React, { useState } from 'react'
import './Consulta.css'

function ConsultaDocumento({ codigoMunicipio, consulta, setConsulta, fetchPredio, fetchPredios }) {

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchPredios();
  }
  const handleInput = (e) => {
    setConsulta({ ...consulta, numeroDocumento: e.target.value })
  }
  const handleSelectTipoDocumento = (e) => {
    setConsulta({ ...consulta, tipoDocumento: e.target.value })
  }
  const handleSelectCodigo = (e) => {
    fetchPredio(e.target.value)
  }

  // this is a functional component defined inside a functional component, bad idea
  const Predios = () => {
    return <select
      onChange={handleSelectCodigo}>
      <option> selecciona un codigo </option>
      {consulta.codigosRelacionados.map((o) => <option value={o}> {o} </option>)}
    </select>
  }

  return (
    <form
      class="MostrarConsulta"
      onSubmit={handleSubmit}>
      <select
        onChange={handleSelectTipoDocumento}
        value={consulta.tipoDocumento}
      >
        <option value="C"> Cedula </option>
        <option value="N"> Nit    </option>
      </select>
      <input
        type="text"
        placeholder="Numero Documento"
        value={consulta.numeroDocumento}
        onChange={handleInput}
      />
      <input
        type="submit"
        value="Consultar"
      />
      {consulta.codigosRelacionados.length === 0
        ? null
        : <Predios />}
    </form>)
}

export default ConsultaDocumento;
