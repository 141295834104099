import React, { useState } from 'react'

function ConsultaPredio({ codigoMunicipio, consulta, setConsulta, fetchPredio }) {
  const handleSubmit = (e) => {
    e.preventDefault();
    fetchPredio(consulta.codigo);
  }
  const handleInput = (e) => {
    setConsulta({ ...consulta, codigo: e.target.value })
  }

  return (
    <form
      class="MostrarConsulta"
      onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Codigo Predio"
        value={consulta.codigo}
        onChange={handleInput}
      />
      <input
        type="submit"
        value="Consultar"
      />
    </form>
  );
}

export default ConsultaPredio;
