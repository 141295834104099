import React, { useEffect, useState } from 'react'
import './Pago.css'

function Pago({ codigoPredio, codigoMunicipio, pago, setPago, togglePagos }) {

  const [op, setOP] = useState("FREE");
  const setOPFree = () => { setOP("FREE"); }
  const setOPWorking = () => { setOP("WORKING"); }

  const handleInputTipoDocumento = (e) => {
    setPago({ ...pago, tipoDocumento: e.target.value })
  }
  const handleInputNumeroDocumento = (e) => {
    setPago({ ...pago, numeroDocumento: e.target.value })
  }
  const handleInputNombreCompleto = (e) => {
    setPago({ ...pago, nombreCompleto: e.target.value })
  }
  const handleInputCorreoElectronico = (e) => {
    setPago({ ...pago, correoElectronico: e.target.value })
  }
  const handleInputNumeroTelefonico = (e) => {
    setPago({ ...pago, numeroTelefonico: e.target.value })
  }

  const handleSubmit = (e) => {
    if (op === "FREE") {
      try {
        e.preventDefault();
        setOPWorking();
        fetchUrlPago();
      }
      finally {
        setOPFree();
      }
    }
  }

  const fetchUrlPago = async () => {
    const result = await
      fetch("https://recibos-web.zendevp.com/pagos"
        , {
          method: 'POST'
          , body: JSON.stringify({
            'CodigoMunicipio': codigoMunicipio
            , 'CodigoPredio': codigoPredio
            , 'Nombre': pago.nombreCompleto
            , 'NumeroDocumento': pago.numeroDocumento
            , 'TipoDocumento': pago.tipoDocumento
            , 'Email': pago.correoElectronico
            , 'Telefono': pago.numeroTelefonico
          })
        })
        .then(response => response.json())
        .then(data => data.Exito
          ? window.open(data.Url, '_blank')
          : alert(data.Mensaje));
    // Cerrar el modal
  }

  return (
    <div class="PagoModalWrapper">
      <form
        class="Pago"
        onSubmit={handleSubmit}>
        <select
          onChange={handleInputTipoDocumento}
          value={pago.tipoDocumento}
          type="text"
          name="TipoDocumento"
          placeholder="Tipo Documento">
          <option value="CC"> Cedula </option>
          <option value="CE"> Cedula de Extranjeria</option>
          <option value="NIT"> NIT </option>
        </select>
        <input
          onChange={handleInputNumeroDocumento}
          value={pago.numeroDocumento}
          type="text"
          name="NumeroDocumento"
          placeholder="Numero Documento"
        />
        <input
          onChange={handleInputNombreCompleto}
          value={pago.nombreCompleto}
          type="text"
          name="NombreCompleto"
          placeholder="Nombre Completo"
        />
        <input
          onChange={handleInputCorreoElectronico}
          value={pago.correoElectronico}
          type="email"
          name="CorreoElectronico"
          placeholder="Correo Electronico"
        />
        <input
          onChange={handleInputNumeroTelefonico}
          value={pago.NumeroTelefonico}
          type="text"
          name="NumeroTelefonico"
          placeholder="Numero Telefonico"
        />
        <input
          style={{ marginTop: "20px" }}
          type="submit"
          value="Pagar"
        />
        <button
          onClick={togglePagos}
          type="button"> Cancelar </button>
      </form>
    </div>
  );
}

export default Pago;
