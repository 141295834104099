import logo from "./logo.svg";
import Consulta from "./Consulta";
import "./App.css";
import React, { useEffect, useState } from "react";

function renderH(municipio) {
  switch (municipio.nombreMunicipio) {
    case "":
      return <div className="Loading"> Cargando... </div>;
    case "?":
      return (
        <div className="Loading">
          {" "}
          no se pudo encontrar el codigo de municipio de la url, asegurese de
          conectarse por medio de la pagina de la alcaldia.{" "}
        </div>
      );
    default:
      return (
        <div className="App">
          <header className="App-header">
            <h2 class="NombreMunicipio">
              {" "}
              Municipio de {municipio.nombreMunicipio} - Impuesto Predial{" "}
            </h2>
          </header>
          <main>
            <Consulta
              nombreMunicipio={municipio.nombreMunicipio}
              codigoMunicipio={municipio.codigoMunicipio}
              tiposConsultaValidos={municipio.tiposConsultaValidos}
              mediosPago={municipio.mediosPago}
            />
          </main>
        </div>
      );
  }
}

function App() {
  const [municipio, setMunicipio] = useState({
    nombreMunicipio: "",
    codigoMunicipio: "",
    tiposConsultaValidos: [],
    mediosPago: [],
  });

  const fetchMunicipio = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const codigoMunicipio = urlParams.get("code");
    await fetch("https://recibos-web.zendevp.com/municipio", {
      method: "POST",
      body: JSON.stringify({ CodigoMunicipio: codigoMunicipio }),
    })
      .then((response) => response.json())
      .then((data) => {
        data.Exito
          ? setMunicipio({
            nombreMunicipio: data.Nombre,
            codigoMunicipio: data.Codigo,
            tiposConsultaValidos: [data?.ConsultaPorCodigoPredio ? "CODIGO_PREDIO" : undefined, data?.ConsultaPorDocumento ? "DOCUMENTO" : undefined].filter(Boolean),
            mediosPago: data.MediosPago,
          })
          : setMunicipio({
            nombreMunicipio: "?",
            codigoMunicipio: "?",
            tiposConsultaValidos: [],
            mediosPago: [],
          });
      });
  };

  useEffect(() => fetchMunicipio(), []);

  return renderH(municipio);
}

export default App;
