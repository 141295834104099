import React, { useState, useReducer } from "react";
import Pago from "./Pago";
import ConsultaDocumento from "./ConsultaDocumento";
import ConsultaPredio from "./ConsultaPredio";
import download from "downloadjs";
import "./Consulta.css";

const cashify = (m) => {
  if (m === "") {
    return m;
  }
  return (
    "$" +
    m
      .split("")
      .reverse()
      .join("")
      .match(/.{1,3}/g)
      .join(".")
      .split("")
      .reverse()
      .join("")
  );
};

const InfoPredio = (predio) => {
  return (
    <div>
      <h3 style={{ textAlign: "center" }}>Informacion del Predio</h3>
      <p style={{ textAlign: "center" }}> {predio.codigoPredio}</p>
      <table class="TablaInfoPredio">
        <tbody>
          <tr style={{ backgroundColor: "#d3ebf7", padding: "10px" }}>
            <th style={{ padding: "10px" }}> Dirección </th>
            <td style={{ padding: "10px", width: "100px" }}>
              {" "}
              {predio.direccionPredio}{" "}
            </td>
          </tr>
          <tr style={{ backgroundColor: "#d3ebf7" }}>
            <th style={{ padding: "10px" }}> Valor a pagar </th>
            <td style={{ padding: "10px" }}> {predio.deudaPredio} </td>
          </tr>
          <tr style={{ backgroundColor: "#d3ebf7" }}>
            <th style={{ padding: "10px" }}> Debe desde </th>
            <td style={{ padding: "10px" }}> {predio.deudaDesdePredio} </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const MostrarConsulta = ({
  codigoMunicipio,
  consulta,
  setConsultaDocumento,
  setConsultaCodigo,
  fetchPredio,
  fetchPredios,
}) => {
  switch (consulta.tipo) {
    case "DOCUMENTO":
      return (
        <ConsultaDocumento
          codigoMunicipio={codigoMunicipio}
          consulta={consulta.consultaDocumento}
          setConsulta={setConsultaDocumento}
          fetchPredio={fetchPredio}
          fetchPredios={fetchPredios}
        />
      );
    case "CODIGO_PREDIO":
      return (
        <ConsultaPredio
          codigoMunicipio={codigoMunicipio}
          consulta={consulta.consultaCodigo}
          setConsulta={setConsultaCodigo}
          fetchPredio={fetchPredio}
        />
      );
    default:
      return null;
  }
};

const SeleccionTipoConsulta = ({ consulta, options }) => {
  return (
    <div class="BotonesTipoConsulta">
      {options.map((o) => {
        return (
          <button
            class={
              o.tipo === consulta.tipo
                ? "BotonTipoConsulta Pushed"
                : "BotonTipoConsulta"
            }
            onClick={o.onClick}
          >
            {o.texto}
          </button>
        );
      })}
    </div>
  );
};

function Consulta({
  nombreMunicipio,
  codigoMunicipio,
  mediosPago,
  tiposConsultaValidos,
}) {
  const [op, setOP] = useState("FREE");
  const setOPFree = () => {
    setOP("FREE");
  };
  const setOPWorking = () => {
    setOP("WORKING");
  };

  const [consulta, setConsulta] = useState({
    tipo: tiposConsultaValidos?.[0],
    consultaCodigo: { codigo: "" },
    consultaDocumento: {
      tipoDocumento: "C",
      numeroDocumento: "",
      codigosRelacionados: [],
    },
  });

  const [predio, setPredio] = useState({
    codigo: "",
    direccion: "",
    deuda: "",
    añoAdeuda: "",
    puedePagar: false,
    reciboDisponible: false,
  });

  const [pago, setPago] = useState({
    showing: false,
    tipoDocumento: "CC",
    numeroDocumento: "",
    nombreCompleto: "",
    correoElectronico: "",
    numeroTelefonico: "",
  });

  const resetPredio = () => {
    setPredio({
      codigo: "",
      direccion: "",
      deuda: "",
      añoAdeuda: "",
      puedePagar: false,
      reciboDisponible: false,
    });
  };

  const setConsultaCodigo = (consultaCodigo_) => {
    setConsulta({ ...consulta, consultaCodigo: consultaCodigo_ });
  };

  const setConsultaDocumento = (consultaDocumento_) => {
    setConsulta({ ...consulta, consultaDocumento: consultaDocumento_ });
  };

  const downloadRecibo = async () => {
    const handleSuccess = (response) => {
      if (response.headers.get("content-type") === "application/json") {
        console.log("hi");
      } else {
        try {
          download(response.blob(), "Recibo.pdf");
        } catch (e) {
          alert(
            "Error, no fue posible descargar el recibo, intenta mas tarde."
          );
        }
      }
    };

    setOPWorking();
    await fetch("https://recibos-web.zendevp.com/recibo", {
      method: "POST",
      body: JSON.stringify({
        CodigoMunicipio: codigoMunicipio,
        CodigoPredio: predio.codigoPredio,
      }),
    })
      .then((response) => {
        if (response.headers.get("content-type") === "application/json") {
          throw "";
        }
        return response.blob();
      })
      .then((blob) => {
        const d = new Date();
        let day = d.getDate();
        day = day.length > 1 ? day : "0" + day;
        let month = d.getMonth();
        month = month.length > 1 ? month : "0" + month;
        const year = d.getFullYear();
        const fecha = `${year}_${month}_${day}`;
        download(
          blob,
          `Recibo_Predial_${predio.codigoPredio}_${nombreMunicipio}_${fecha}.pdf`
        );
      })
      .catch((err) =>
        alert("No fue posible descargar el recibo, intenta mas tarde")
      ); // TODO: debe haber una forma mas elegante de manejar este error

    setOPFree();
  };

  const fetchPredio = async (codigoPredio) => {
    setPredio({
      codigo: "",
      direccion: "",
      deuda: "",
      añoAdeuda: "",
      puedePagar: false,
      reciboDisponible: false,
    });

    const handleSuccess = (data) => {
      if (data.Exito) {
        setPredio({
          codigoPredio: data.Codigo,
          direccionPredio: data.Direccion,
          deudaPredio: cashify(data.DeudaTotal),
          deudaDesdePredio: data.AnnoAdeuda,
          puedePagar: data.PuedePagar,
          reciboDisponible: data.ReciboDisponible,
        });
      }

      if (!data.Exito || data.Mensaje !== "") {
        alert(data.Mensaje);
      }
    };
    await fetch("https://recibos-web.zendevp.com/predio-codigo", {
      method: "POST",
      body: JSON.stringify({
        CodigoMunicipio: codigoMunicipio,
        CodigoPredio: codigoPredio,
      }),
    })
      .then((response) => response.json())
      .then((data) => handleSuccess(data));
  };

  const fetchPredios = async () => {
    setConsulta({ ...consulta, codigosRelacionados: [] });
    let result = await fetch(
      "https://recibos-web.zendevp.com/predios-documento",
      {
        method: "POST",
        body: JSON.stringify({
          CodigoMunicipio: codigoMunicipio,
          TipoDocumento: consulta.consultaDocumento.tipoDocumento,
          NumeroDocumento: consulta.consultaDocumento.numeroDocumento,
        }),
      }
    );
    if (result.status === 200) {
      let data = await result.json();
      if (data.Exito) {
        setConsulta({
          ...consulta,
          consultaDocumento: {
            ...consulta.consultaDocumento,
            codigosRelacionados: data.Codigos,
          },
        });
      }
      if (!data.Exito || data.Codigos.length === 0 || data.Mensaje !== "") {
        alert(data.Mensaje);
      }
    } else {
      alert("No fue posible realizar la consulta, intenta mas tarde.");
    }
  };

  const setTipoConsultaToCodigo = () => {
    setConsulta({ ...consulta, tipo: "CODIGO_PREDIO" });
  };

  const setTipoConsultaToDocumento = () => {
    setConsulta({ ...consulta, tipo: "DOCUMENTO" });
  };

  const togglePagos = () => {
    setPago({ ...pago, showing: !pago.showing });
  };

  const tipoConsultaValidoP = (t) => {
    return tiposConsultaValidos?.includes(t);
  };

  return (
    <div class="Consulta">
      <p style={{ textAlign: "center" }}>
        Consulta {mediosPago.length >= 1 ? "y Paga" : null} tu recibo del
        impuesto predial
      </p>
      <SeleccionTipoConsulta
        consulta={consulta}
        options={[
          {
            tipo: "DOCUMENTO",
            texto: "Consultar por Documento del Propietario",
            onClick: () => {
              setTipoConsultaToDocumento();
              resetPredio();
            },
          },
          {
            tipo: "CODIGO_PREDIO",
            texto: "Consultar por Codigo del Predio",
            onClick: () => {
              setTipoConsultaToCodigo();
              resetPredio();
            },
          },
        ].filter((t) => tipoConsultaValidoP(t.tipo))}
      />
      <MostrarConsulta
        codigoMunicipio={codigoMunicipio}
        consulta={consulta}
        setConsultaDocumento={setConsultaDocumento}
        setConsultaCodigo={setConsultaCodigo}
        fetchPredio={fetchPredio}
        fetchPredios={fetchPredios}
      />
      {predio.codigo === "" ? null : <InfoPredio {...predio} />}
      {predio.reciboDisponible ? (
        <button
          style={{ width: "200px" }}
          class="Boton"
          onClick={() => {
            if (op === "FREE") {
              downloadRecibo();
            } else {
              console.log("prevented action");
            }
          }}
        >
          {" "}
          Descargar Recibo{" "}
        </button>
      ) : null}

      {predio.puedePagar && predio.codigo !== "" && mediosPago.length >= 1 ? (
        <button class="Boton" style={{ width: "200px" }} onClick={togglePagos}>
          {" "}
          Pagar Recibo{" "}
        </button>
      ) : null}

      {predio.puedePagar &&
        predio.codigo !== "" &&
        pago.showing &&
        mediosPago.length >= 1 ? (
          <Pago
            codigoPredio={predio.codigoPredio}
            codigoMunicipio={codigoMunicipio}
            pago={pago}
            setPago={setPago}
            togglePagos={togglePagos}
          />
        ) : null}
    </div>
  );
}

export default Consulta;
